<template>
  <material-card
    color="primary"
    class="px-5 py-3"
  >
    <template v-slot:heading>
      <div class="body-1">
        Cut Plans
      </div>
    </template>
    <v-card-text>
      New Cut Plan
    </v-card-text>
  </material-card>
</template>

<script>
export default {
  name: 'CutPlan',
  data: () => ({ loading: true })
}
</script>
